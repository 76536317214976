import React from "react";
import { Link } from "gatsby";
import { styled } from "../../stitches/stitches.config";
import ArticleIcon from "./assets/article.inline.svg";
import Button from "../shared/Button/Button";

interface BlogArticleLinkProps {
  path: string;
}

const BlogArticleLink = ({ path }: BlogArticleLinkProps) => {
  return (
    <>
      <DesktopLink>
        <ArticleIconCenter /> Read more in our{" "}
        <ArticleLink to={path}>technical blog article</ArticleLink>
      </DesktopLink>
      <MobileLink>
        <Link to={path}>
          <Button tier="bordered" arrow size="small">
            Read our blog article
          </Button>
        </Link>
      </MobileLink>
    </>
  );
};

const ArticleIconCenter = styled(ArticleIcon, {
  verticalAlign: "middle",
  marginRight: 8,
});

const ArticleLink = styled(Link, {
  fontWeight: 700,
  color: "$plxBlue",
});

const MobileLink = styled("div", {
  "@sm": {
    display: "none",
  },
});
const DesktopLink = styled("div", {
  display: "none",
  "@sm": {
    display: "block",
  },
});

export default BlogArticleLink;

import React from "react";
import { styled } from "../../stitches/stitches.config";

interface VideoProps {
  link: string;
}

const Video = ({ link }: VideoProps) => {
  return (
    <VideoWrapper>
      <VideoIframe
        src={link}
        frameBorder="0"
        width="100%"
        height="100%"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></VideoIframe>
    </VideoWrapper>
  );
};

const VideoWrapper = styled("div", {
  padding: "16px 0",
  "@md": {
    padding: "32px 0",
  },
});

const VideoIframe = styled("iframe", {
  width: "100%",
  height: "50vw",
  maxHeight: 400,
  borderRadius: 25,
  filter: "drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.35))",
});

export default Video;

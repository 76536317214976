import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import BlogArticleLink from "../components/comparisonPage/BlogArticleLink";
import ComparisonTable, {
  FeatureProps,
} from "../components/comparisonPage/ComparisonTable";
import Video from "../components/comparisonPage/Video";

import Layout from "../components/Layout";
import Hero from "../components/shared/Hero/Hero";
import TextButtonsImage from "../components/shared/TextButtonsImage";
import TextWithTitle from "../components/shared/TextWithTitle/TextWithTitle";
import HideOnScreen from "../components/shared/HideOnScreen";
import ProductDisplay from "../components/shared/ProductsDisplay/ProductsDisplay";
import unescape from "../utils/usescape";

function PipVsHardnessPage() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "hero-background.png" }) {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: TRACED_SVG)
        }
      }
      benchtop: file(relativePath: { eq: "benchtop_render_v3_desktop.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
        }
      }
      mobileImage: file(relativePath: { eq: "benchtop_render_v3_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
        }
      }
      markdownRemark(fileAbsolutePath: { regex: "/vsHardness/" }) {
        frontmatter {
          seoTitle
          seoDescription
          hero {
            title
            button {
              text
              path
            }
          }
          basicInfo {
            title
            byline
            video
            button {
              text
              path
            }
          }
          featuresSection {
            title
            ourTitle
            theirsTitle
            features {
              name
              our
              theirs
            }
          }
          firstSection {
            title
            articleBefore
            articleAfter
            blogLink
            video
          }
        }
      }
    }
  `);

  const {
    seoTitle,
    seoDescription,
    hero,
    basicInfo,
    featuresSection,
    firstSection,
  } = data.markdownRemark.frontmatter;

  const bg = undefined;
  const image = getImage(data.benchtop);
  const mobileImage = getImage(data.mobileImage);
  const light = undefined;
  const className = "benchtop";  
  return (
    <Layout
      seo={{
        title: seoTitle,
        description: seoDescription,
      }}
      backgroundColor="white"
      backgroundColorUnderneath="white"
    >
      <Hero
        title={unescape(hero.title)}
        secondaryButton={{
          text: hero.button.text,
          path: hero.button.path,
        }}
        byline={basicInfo.byline}
        hideBylineOnType="mobile"
        hideBylineOnBreakpoint="md"
        image={image}
        mobileImage={mobileImage}
        bg={bg}
        light={light}
      />
      <HideOnScreen variant="desktop" breakpoint="md">
        <TextButtonsImage
          title={basicInfo.title}
          byline={basicInfo.byline}
          secondaryButton={{
            text: basicInfo.button.text,
            path: basicInfo.button.path,
            tier: "bordered",
            padding: true,
          }}
          //video={basicInfo.video}
          video="https://player.vimeo.com/video/765993773?autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0"
          
          hideOnMobile
          demoButton={false}
          variant="small"
        />
      </HideOnScreen>

      <ComparisonTable {...featuresSection} />
      <TextWithTitle title={unescape(firstSection.title)}>
        {firstSection.articleBefore}
        <HideOnScreen variant="mobile" breakpoint="md">
          <Video link="https://player.vimeo.com/video/780795779?autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0" />
        </HideOnScreen>
        {firstSection.articleAfter}
        <BlogArticleLink path={firstSection.blogLink} />
        <HideOnScreen variant="desktop" breakpoint="md">
        <Video link="https://player.vimeo.com/video/780795779?autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0" />
        </HideOnScreen>
      </TextWithTitle>
      <ProductDisplay />
    </Layout>
  );
}

export default PipVsHardnessPage;
